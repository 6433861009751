<template>
  <div class="User-Group">
    <div class="page-container-table">
      <!--=============== Bắt đầu phần header trên cùng================= -->
      <button-all-header
        :contentBtnAdd="'Thêm nhóm người dùng'"
        :arrayExcel="arrayExcel"
        :showBtnMultiDelete="showBtnMultiDelete"
        @clickDelete="deleteMore"
        @clickDowloadSample="downloadSampleUserGroup"
        @clickExportExcel="downloadExcelAll"
        @importFile="importFileExcel($event)"
        @clickAdd="$router.push('/User-Group/Add')"
        @search="search($event)"
      />
      <!--=============== Kết thúc phần header trên cùng================= -->
      <!--=================== Bắt đầu phần bảng==================== -->
      <div class="User-Group__main__table">
        <!--================================= Bảng vue good table ====================== -->
        <vue-good-table
          :columns="columns"
          :rows="rows"
          :sort-options="{
            enabled: false,
            initialSortBy: [
              {field: 'name', type: 'asc'},
              {field: 'code', type: 'desc'}
            ],
          }"
          :select-options="{ enabled: true, selectOnCheckboxOnly: true }"
          @on-selected-rows-change="selectRowTable"
        >
          <!--=================== chỉnh sửa phần chức năng trong bảng==================== -->
          <template
            slot="table-row"
            slot-scope="props"
          >
            <span v-if="props.column.field == 'cn'">
              <span><feather-icon
                icon="EditIcon"
                size="18"
                class="table__chucnang__icon"
                @click="edit(props.row.id)"
              /><feather-icon
                icon="Trash2Icon"
                size="18"
                class="table__chucnang__icon"
                style="margin-left: 14px"
                @click.stop="handleClickDeleteButton(props.row.id)"
              /></span>
            </span>
          </template>
          />
        </vue-good-table>
      </div>
      <!--=================== Kết thúc phần bảng=================== -->
      <div class="User-Group__main__footer">
        <my-pagination
          :totalItems="totalPages || 0 "
          :currentPage="currentPage"
          @pageClick="handlePageClick"
        />
      </div>
    </div>
    <b-modal
      id="delete-modal"
      ref="delete-modal"
      :title="'Thay thế nhóm người dùng muốn xóa'"
      :ok-title="'Đồng ý'"
      :cancel-title="'Hủy bỏ'"
      cancel-variant="outline-primary"
      no-close-on-backdrop
      @ok="handelDelete"
      @hidden="resetDeleteModel"
    >
      <validation-observer ref="rule">
        <b-form>
          <b-form-group>
            <label for="code">Nhóm người dùng thay thế<span class="required">(*)</span></label>
            <validation-provider
              #default="{ errors }"
              :custom-messages="customSelectDel"
              rules="required"
            >
              <v-select
                v-model="deleteModel.groupUserId"
                :class="errors.length > 0 ? 'is-invalid':null"
                :reduce="name => name.id"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                label="name"
                :options="allPosition || []"
                :placeholder="'Nhóm người dùng thay thế'"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-form>
      </validation-observer>
    </b-modal>
  </div>
</template>

<script>
// =====================================================Phân import file ======================================//
import VSelect from 'vue-select'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { mapActions, mapState, mapMutations } from 'vuex'
import {
  BButton,
} from 'bootstrap-vue'
// eslint-disable-next-line import/no-unresolved
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import readXlsxFile from 'read-excel-file'
import Ripple from 'vue-ripple-directive'
import VueGoodTable from '@/components/table/VueGoodTable.vue'
import MyPagination from '@/components/pagination/MyPagination.vue'
import Search from '@/components/search/Search.vue'
import ButtonAllHeader from '@/views/common/ButtonAllHeader.vue'

export default {
  components: {
    BButton,
    // eslint-disable-next-line vue/no-unused-components
    VueGoodTable,
    ValidationProvider,
    ValidationObserver,
    MyPagination,
    Search,
    VSelect,
    ButtonAllHeader,
  },
  directives: {
    Ripple,
  },

  //= =========================== dữ liệu data ==========================//
  data() {
    return {
      arrayExcel: ['code', 'name', 'description'],
      customSelectDel: {
        required: 'Lựa chọn nhóm người dùng thay thế là bắt buộc',
      },
      showBtnMultiDelete: false,
      selectTable: '',
      deleteModel: {
        id: '',
        groupUserId: '',
      },
      allPosition: '',
      currentPage: 1, // ========================trang hiện tại==============================//
      dataTable: '', // ============mảng trả về dữ liệu của bảng để hiển thị ===============//
      selectDown: [5, 10, 20], //= ==========Lựa chọn số trang hiên thị =====================//
      perpage: 5, //= ====================  Số trang hiển thị trên bảng =========================//
      totalPages: '', // =====================  Tổng só trang   ====================================//
      valueInput: '', //= =======================Dữ liệu từ input seach ==============================//
      // currentPage: 0,
      urlQuery: {
        key: '',
        pageSize: 10,
        pageNumber: 1,
      },
      columns: [
        {
          label: 'Mã nhóm',
          field: 'code',
          tdClass: 'style-code',
        },
        {
          label: 'Tên nhóm',
          field: 'name',
        },
        {
          label: 'Chức năng',
          field: 'cn',
          tdClass: 'table__chucnang',
          thClass: 'table__chucnang__th',
        },
      ],
      rows: [
      ],
    }
  },
  // eslint-disable-next-line vue/order-in-components
  computed: {
    ...mapState('userGroup', ['dataTableState']),
  },
  // =====================================Phần Created=================================//
  async created() {
    await this.fetchdata()
  },
  // =====================================Phần methods ================================//
  methods: {
    ...mapActions('userGroup', ['fetchUserGroup', 'downloadSampleUserGroup', 'getApiExcelUserGroup', 'getAllComboBox', 'fetchUserGroupToId', 'deleteUserGroup', 'downloadExcelAll']),
    ...mapMutations('userGroup', ['DATA_EDIT_USER_GROUP']),
    // ========================================================lấy dũ liệu bảng =============================================//
    async fetchdata() {
      const datafetchUserGroup = await this.fetchUserGroup(this.urlQuery)
      this.rows = datafetchUserGroup.data.pageLists
      this.totalPages = datafetchUserGroup.data.totalRecord
    },
    // =========================================================Phần phân trang ==========================================
    async handlePageClick(pageNumber, pageSize) {
      this.urlQuery.pageNumber = pageNumber
      this.urlQuery.pageSize = pageSize
      await this.fetchdata()
    },
    // ============================================================Phân tìm kiếm -==========================================
    async search(val) {
      if (val != null) {
        this.urlQuery.pageNumber = 1
        this.urlQuery.key = val
        await this.fetchdata()
      } else {
        this.urlQuery.pageNumber = 1
        this.urlQuery.key = ''
        await this.fetchdata()
      }
    },
    // ==========================================================import file exel ================================//
    async importFileExcel(event) {
      if (event) {
        this.getValidData(event)
      }
    },
    checkRowExcel(a) {
      if (a === null) {
        return a
      }
      return a.toString()
    },
    //= ================================================kiểm tra hợp lệ từ file exel========================//
    async getValidData(dataInput) {
      const model = {
        listExcel: dataInput,
        isValidate: true,
      }
      await this.getApiExcelUserGroup(model)
      await this.$router.push('User-Group/list')
    },

    // ============================================================Phân sửa =================================================//
    async edit(data) {
      this.$router.push(`/User-Group/Edit/${data}`)
    },
    // chọn hàng table
    selectRowTable(val) {
      this.deleteModel.id = val.selectedRows.map(e => e.id)
      this.showBtnMultiDelete = this.deleteModel.id.length > 0
    },
    // ấn vào nút xóa
    async handleClickDeleteButton(id) {
      const comboBox = await this.getAllComboBox([id])
      this.allPosition = comboBox.data.data
      this.deleteModel.id = [id]
      this.$bvModal.show('delete-modal')
    },
    // Ấn nút xóa nhiều
    async deleteMore() {
      const comboBox = await this.getAllComboBox(this.deleteModel.id)
      this.allPosition = comboBox.data.data
      this.$bvModal.show('delete-modal')
    },
    async handelDelete(bvModalEvt) {
      bvModalEvt.preventDefault()
      this.$refs.rule.validate().then(async success => {
        if (success) {
          const response = await this.deleteUserGroup(this.deleteModel)
          this.fetchdata()
          let variant = 'success'
          if (response.status === 200) {
            this.showToast('success', 'Xóa thành công!')
            this.deleteModel.groupUserId = ''
            this.$nextTick(() => {
              this.$refs['delete-modal'].toggle('#toggle-btn')
            })

            if (this.tableRows.length === 1) this.urlQuery.pageNumber = this.urlQuery.pageNumber > 1 ? this.urlQuery.pageNumber - 1 : 1
            this.fetchData()
          } else {
            variant = 'danger'
          }
          this.$bvToast.toast('Xóa bị lỗi', {
            title: 'Xóa bị lỗi', variant, toaster: this.$toastPosition, solid: true,
          })
          this.deleteModel.groupUserId = ''
        }
      })
    },
    resetDeleteModel() {
      this.deleteModel.userTypeNewId = null
    },
    showToast(variant, nameAlert) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Thông báo',
          icon: 'BellIcon',
          text: nameAlert,
          variant,
        },
      })
    },
  },

}
</script>

<style lang="scss">
// .User-Group {

//     &__footer {
//       margin-top: 77px;
//       padding: 20px;
//       &__left {
//         display: flex;
//         align-items: center;
//         &__item {
//           margin-right: 10px;
//         }
//       }
//     }
//   }

// label,
// p {
//   margin: 0px;
// }
.style-code span {
  background: rgba(34, 127, 244, 0.12);
  border-radius: 4px;
  padding: 1px 9px;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  text-align: center;
  color: #227FF4;
}
</style>
